<template>
<div>
    <div class="flex">
      <div v-for="item in dataList" :key="item.id" class="m-r2">
        <a-button :loading="loading" @click="handleClick(item)" type="primary">{{ item.name }}</a-button>
      </div>
    </div>
</div>
</template>

<script setup>
import { ref } from 'vue'
import { receivableSummaryExport, receivableTransExport, receivableArrivalExport, receivableOrderExport, receivableOrderInnerExport} from '@/api/finance/income'
import { message } from 'ant-design-vue'

const loading = ref(false)

const handleClick = (item) => {
  let reqData = null
  switch(item.id){
    case 1:
    reqData = receivableSummaryExport
    break;
    case 2:
    reqData = receivableTransExport
    break;
    case 3:
    reqData = receivableArrivalExport
    break;
    case 4:
    reqData = receivableOrderExport
    break;
    case 5:
    reqData = receivableOrderInnerExport
    break;
  }
  loading.value = true
  reqData({},item.name).then(res => {
  }).finally(() => {
    loading.value = false
  })
}

const dataList = ref([
  {
    name: '应收应付-汇总',
    id: 1
  },
  {
    name: '应付明细',
    id: 2
  },
  {
    name: '代收明细',
    id: 3
  },
  {
    name: '应收明细',
    id: 4
  },
  {
    name: '内部代收明细',
    id: 5
  },
])
</script>

<style lang="less" scoped>

</style>